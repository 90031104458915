<template>
  <div class="txt-black txt-16">
    <p v-html="$t('components.notifications.restrictedFieldsInTheTranslationTemplate')">
    </p>
    <p>{{ $t('components.notifications.restrictedCellWillDisplayAWarning') }}</p>
  </div>
</template>

<script>
export default {
  props: {
    languages: {
      type: Array,
      default: () => []
    }
  }
};
</script>
